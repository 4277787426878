import type { ReactElement } from 'react';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import BpkSmallChevronLeft from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-left';
import BpkSmallChevronRight from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-right';
import BpkPageIndicator from '@skyscanner/backpack-web/bpk-component-page-indicator';
import { isRTL } from '@skyscanner/backpack-web/bpk-react-utils';

import STYLES from './DesktopScrollContainer.module.scss';

type DesktopScrollContainerProps = {
  nextLabel: string;
  prevLabel: string;
  singleCardPerPage?: boolean;
  children: ReactElement[];
  logPrevEvent?: () => void;
  logNextEvent?: () => void;
  setVisibleIndexs?: (args: number[]) => void;
  bottomNav?: boolean;
  navPosition?: 'top' | 'bottom';
  selectedItem?: number;
  onClickPageIndicator?: () => void;
  onScrollComplete?: (args: number) => void;
};

type ClickName = 'prev' | 'next';

const DEBOUNCE_TIME = 150;
const SCROLL_COMPLETE_TOLERANCE = 2;

const AlignedSmallChevronLeft = withButtonAlignment(
  withRtlSupport(BpkSmallChevronLeft),
);
const AlignedSmallChevronRight = withButtonAlignment(
  withRtlSupport(BpkSmallChevronRight),
);

const NextIcon = AlignedSmallChevronRight;
const PrevIcon = AlignedSmallChevronLeft;

/*
  Provide horizontal scrolling for cards in desktop device,
  - bpk-breakpoint-above-tablet: Display three cards on a page
  - bpk-breakpoint-tablet: Display two cards on a page

  Example:
      <DesktopScrollContainer>
        {cardList.map((card) => (
          <Card
            key={card.name}
          />
        ))}
      </DesktopScrollContainer>
*/
const DesktopScrollContainer = forwardRef(
  (
    {
      bottomNav,
      children,
      logNextEvent,
      logPrevEvent,
      navPosition,
      nextLabel,
      onClickPageIndicator,
      onScrollComplete,
      prevLabel,
      selectedItem,
      setVisibleIndexs,
      singleCardPerPage,
    }: DesktopScrollContainerProps,
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);

    const [containerWidth, setContainerWidth] = useState(0);
    // The index of the first card on the current page
    const [cardIndex, setCardIndex] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(false);

    useEffect(() => {
      // setVisibleIndexs will affect children, so we need to rule out using setVisibleIndexs
      if (!setVisibleIndexs) {
        setCardIndex(0);
        setPageIndex(0);
      }
    }, [children]);

    /*
  Calculate the number of cards that can be displayed on a page
  The +2 is to avoid the calculation error caused by clientWidth in rounding
  */
    const calculateNumberOfDisplay = (): number =>
      Math.floor((containerWidth + 2) / cardWidth);

    /*
  Judge whether to reach the last page
  params:
    nextCardIndex: Index of the first card on the next page
  */
    const touchEnd = (nextCardIndex: number): boolean =>
      (numberOfCards - nextCardIndex) * cardWidth <= containerWidth;

    /*
  Judge whether to reach the first page
  params:
    nextCardIndex: Index of the first card on the next page
  */
    const touchStart = (nextCardIndex: number): boolean => nextCardIndex <= 0;

    /*
  Judge whether to reach the first page
  params:
    xOffset: The relative offset of the X axis relative to the initial position
  */
    const setScrollPosition = (xOffset: number) => {
      if (!containerRef.current?.scroll) {
        return;
      }
      containerRef.current.scroll({
        left: isRTL() ? -xOffset : xOffset,
        behavior: 'smooth',
      });
    };

    // The number of all cards
    const numberOfCards = children.length;
    // The number of cards that can be displayed on a page
    let numberOfDisplay = calculateNumberOfDisplay();
    let indicatorDifference: number;

    const isOnlyOnePage = numberOfDisplay >= numberOfCards;

    const pages = numberOfDisplay
      ? Math.ceil(numberOfCards / numberOfDisplay)
      : 1;

    const resetPagination = () => {
      setScrollPosition(0);
      setCardIndex(0);
      setPageIndex(0);
      setPrevDisabled(true);
      setNextDisabled(false);
    };

    const debouncedGetWidth = debounce(() => {
      if (!containerRef.current) {
        return;
      }
      // Get the latest width of the container and card
      setContainerWidth(containerRef.current?.clientWidth || 0);
      setCardWidth(cardRef.current?.clientWidth || 0);

      // Recalculate and initialize component position
      numberOfDisplay = calculateNumberOfDisplay();
      resetPagination();
    }, DEBOUNCE_TIME);

    // Expose the reset method using useImperativeHandle
    useImperativeHandle(ref, () => ({
      resetPagination,
    }));

    // Initialize the container and card widths
    useEffect(() => {
      setContainerWidth(containerRef.current?.clientWidth || 0);
      setCardWidth(cardRef.current?.clientWidth || 0);
    }, []);

    // Keep the selected item in view
    useEffect(() => {
      if (typeof selectedItem === 'undefined' || selectedItem === pageIndex) {
        return;
      }

      const nextCardIndex = selectedItem * calculateNumberOfDisplay();
      setCardIndex(nextCardIndex);
      setPageIndex(selectedItem);

      setPrevDisabled(false);
      setNextDisabled(false);

      // Update the disabled state of the next and previous buttons
      if (touchEnd(nextCardIndex)) {
        setNextDisabled(true);
      }

      if (touchStart(nextCardIndex)) {
        setPrevDisabled(true);
      }
    }, [
      selectedItem,
      calculateNumberOfDisplay,
      pageIndex,
      touchEnd,
      touchStart,
    ]);

    // When the scroll animation is complete, call the onScrollComplete function
    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const handleScrollComplete = () => {
        const itemWidth = container.firstElementChild?.clientWidth;
        if (!itemWidth) return;

        // Calculate the index of the visible item
        const index = Math.round(Math.abs(container.scrollLeft) / itemWidth);

        // Check if the scroll position aligns with the start of the card
        const cardPosition = index * itemWidth;
        const aligns =
          Math.abs(container.scrollLeft) - cardPosition <=
          SCROLL_COMPLETE_TOLERANCE;

        if (aligns) {
          if (index === selectedItem || index === cardIndex) {
            onScrollComplete?.(index);
          }
        }
      };

      container.addEventListener('scroll', handleScrollComplete);

      // eslint-disable-next-line consistent-return
      return () => {
        container.removeEventListener('scroll', handleScrollComplete);
      };
    }, [onScrollComplete, selectedItem, cardIndex]);

    // When the index of the first card on the page changes, the page is turned
    useEffect(() => {
      setScrollPosition(cardIndex * cardWidth);
    }, [cardIndex, cardWidth]);

    useEffect(() => {
      if (numberOfDisplay !== Infinity && numberOfDisplay > 0) {
        setVisibleIndexs &&
          setVisibleIndexs(
            Array.from(Array(numberOfDisplay), (_, i) => i + cardIndex),
          );
      }
    }, [cardIndex, numberOfDisplay, setVisibleIndexs]);

    // Listen for screen width
    useEffect(() => {
      window.addEventListener('resize', debouncedGetWidth);
      return () => {
        window.removeEventListener('resize', debouncedGetWidth);
      };
    });

    const getNextCardIndex = (
      pageIndicatorClicked: boolean,
      clickType: ClickName,
    ) => {
      if (clickType === 'next') {
        if (pageIndicatorClicked) {
          return cardIndex + numberOfDisplay * indicatorDifference;
        }
        return cardIndex + numberOfDisplay;
      }

      if (pageIndicatorClicked) {
        return cardIndex - numberOfDisplay * indicatorDifference;
      }
      return cardIndex - numberOfDisplay;
    };

    /*
    Handling page turns
    params:
      clickType: 'next' | 'prev'
  */
    const clickNav = (clickType: ClickName) => {
      if (!containerWidth || !cardWidth) {
        return;
      }
      if (clickType === 'next') {
        const nextCardIndex = getNextCardIndex(
          indicatorDifference !== undefined,
          'next',
        );
        setPrevDisabled(false);
        if (touchEnd(nextCardIndex)) {
          setNextDisabled(true);
          setCardIndex(numberOfCards - numberOfDisplay);
        } else {
          setCardIndex(nextCardIndex);
        }
        logNextEvent && logNextEvent();
      }
      if (clickType === 'prev') {
        const nextCardIndex = getNextCardIndex(
          indicatorDifference !== undefined,
          'prev',
        );
        setNextDisabled(false);
        if (touchStart(nextCardIndex)) {
          setPrevDisabled(true);
          setCardIndex(0);
        } else {
          setCardIndex(nextCardIndex);
        }
        logPrevEvent && logPrevEvent();
      }
    };

    if (children.length === 0) {
      return null;
    }

    return (
      <div className={STYLES.DesktopScrollContainer}>
        <div
          data-testid="container"
          className={STYLES.DesktopScrollContainer__container}
          ref={containerRef}
        >
          {children.map((card, index) => (
            <div
              key={`card-${index + 1}`}
              className={classNames(
                STYLES.DesktopScrollContainer__card,
                singleCardPerPage &&
                  STYLES[`DesktopScrollContainer__card--single`],
              )}
              ref={cardRef}
            >
              {card}
            </div>
          ))}
        </div>
        {bottomNav && (
          <BpkPageIndicator
            className={STYLES.DesktopScrollContainer__bottomNav}
            currentIndex={pageIndex}
            totalIndicators={pages}
            onClick={(_e: MouseEvent, newIndex: number) => {
              indicatorDifference = Math.abs(newIndex - pageIndex);
              onClickPageIndicator?.();
              clickNav(pageIndex < newIndex ? 'next' : 'prev');
              setPageIndex(newIndex);
            }}
            indicatorLabel="Go to page"
            prevNavLabel={prevLabel}
            nextNavLabel={nextLabel}
            showNav
          />
        )}
        {!isOnlyOnePage && !bottomNav && (
          <div
            className={classNames(
              STYLES.DesktopScrollContainer__nav,
              STYLES[`DesktopScrollContainer__nav--${navPosition}`],
            )}
          >
            <BpkButton
              secondary
              onClick={() => {
                clickNav('prev');
              }}
              disabled={prevDisabled}
              aria-label={prevLabel}
            >
              <PrevIcon />
            </BpkButton>
            <BpkButton
              secondary
              onClick={() => {
                clickNav('next');
              }}
              disabled={isOnlyOnePage || nextDisabled}
              aria-label={nextLabel}
            >
              <NextIcon />
            </BpkButton>
          </div>
        )}
      </div>
    );
  },
);

DesktopScrollContainer.defaultProps = {
  setVisibleIndexs: undefined,
  logPrevEvent: undefined,
  logNextEvent: undefined,
  bottomNav: false,
  onClickPageIndicator: undefined,
  navPosition: 'top',
  onScrollComplete: undefined,
  selectedItem: undefined,
  singleCardPerPage: false,
};

export default DesktopScrollContainer;
